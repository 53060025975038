import API from '@/modules/API';

export async function addVirtualDevice(name,companyId,projectId){
    const body = {
        device_name: name,
        company_id: companyId,
        project_id: projectId
    }
    const apikey = await JSON.parse(localStorage.getItem('user')).apikey
    try {
        const url = "https://dashboard-api.tondo-iot.com/device/create/";
        const options = {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                'x-api-key': apikey
            }
        }
        const res = await fetch(url, options);
        const resJson = await res.json();
        return resJson.device_id;
        
    } catch (e) {
        console.log(e);
        return null;
    }
}
export async function deleteVirtualDevice(companyId,projectId,id){
    const body = {
        device_id: id,
        company_id: companyId,
        project_id: projectId
    }
    const apikey = await JSON.parse(localStorage.getItem('user')).apikey
    try {
        const url = "https://dashboard-api.tondo-iot.com/device/delete";
        const options = {
            method: "DELETE",
            body: JSON.stringify(body),
            headers: {
                'x-api-key': apikey
            }
        }
        const res = await fetch(url, options);
        const resJson = await res.json();
        return resJson;
        
    } catch (e) {
        console.log(e);
        
    }
}